import { useRouter } from 'next/router'

import useCart from '../store/cart'

import useInterval from '../hooks/useInterval'

import CartItem from './CartItem'

export const CART_REFRESH_INTERVAL = 15000 // 15s

const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
})

export default function Cart() {
  const router = useRouter()
  const [isOpen, setIsOpen] = useCart((state) => [state.isOpen, state.setIsOpen])
  const { items, updateItemStock } = useCart((state) => ({ 
    items: state.items,
    updateItemStock: state.updateItemStock,
  }))
  const cartCount = items.reduce((total, item) => total + item.quantity, 0)
  const subTotal = items.reduce((total, item) => {
    if (item.variant?.stock === 0) return total
    return total + (item.quantity * (item.variant?.price || 0))
  }, 0)

  const handleClickedCheckout = () => {
    setIsOpen(false)
    router.push('/checkout')
  }

  useInterval(() => {
    items.forEach(async item => {
      const product = await fetch(`/api/products/${item.product.id}`).then((response) => response.text()).then(result => JSON.parse(result))
      updateItemStock(item, product)
    })
  }, CART_REFRESH_INTERVAL, [updateItemStock])

  return <>
    <div className="flow-root">
      <button 
        onClick={() => setIsOpen(state => !state)}
        type="button"
        className="group m-0 w-full flex items-center justify-center uppercase text-center px-3 pr-4 py-2 rounded disabled:opacity-30 disabled:cursor-not-allowed text-black bg-primary hover:opacity-80 active:opacity-70 cart-button"
      >
       <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path d="M6.7085 16.2493C5.65433 16.2493 4.80141 17.1118 4.80141 18.166C4.80141 19.2202 5.65433 20.0827 6.7085 20.0827C7.76266 20.0827 8.62516 19.2202 8.62516 18.166C8.62516 17.1118 7.76266 16.2493 6.7085 16.2493ZM0.958496 0.916016V2.83268H2.87516L6.32516 10.1064L5.03141 12.4543C4.87808 12.7227 4.79183 13.0389 4.79183 13.3743C4.79183 14.4285 5.65433 15.291 6.7085 15.291H18.2085V13.3743H7.111C6.97683 13.3743 6.87141 13.2689 6.87141 13.1348L6.90016 13.0198L7.76266 11.4577H14.9022C15.621 11.4577 16.2535 11.0648 16.5793 10.4706L20.0102 4.25102C20.0868 4.11685 20.1252 3.95393 20.1252 3.79102C20.1252 3.26393 19.6939 2.83268 19.1668 2.83268H4.99308L4.09225 0.916016H0.958496ZM16.2918 16.2493C15.2377 16.2493 14.3847 17.1118 14.3847 18.166C14.3847 19.2202 15.2377 20.0827 16.2918 20.0827C17.346 20.0827 18.2085 19.2202 18.2085 18.166C18.2085 17.1118 17.346 16.2493 16.2918 16.2493Z" fill="black"/></svg>
        {/* <span className="ml-2 text-sm font-semibold text-gray-900">{cartCount}</span> */}
        <span className="sr-only">items in cart, view bag</span>
      </button>
    </div>
    
    <div onClick={() => setIsOpen(false)} className={`cursor-pointer fixed top-0 left-0 w-screen h-screen bg-gray-900 transition-opacity ease-in-out duration-100 ${isOpen ? 'opacity-0 z-10' : 'opacity-0 -z-20'}`} />
    <div className={`absolute top-5 right-5 lg:right-0 bg-white shadow-2xl p-6 border border-gray-50 rounded-lg max-w-md w-full transition-all ease-in-out transform ${isOpen ? 'opacity-100 scale-100 z-20' : 'opacity-0 scale-90 -z-10'}`}>
      <h1 className="text-xl font-bold tracking-tight text-gray-900">Cart</h1>

      <button onClick={() => setIsOpen(false)} type="button" className="cursor-pointer absolute top-5 right-3 p-2 inline-flex text-gray-900">
        <span className="sr-only">Close</span>
        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>

      {items.length > 0 && (<>
        <ul role="list" className="mt-5 border-t border-b border-gray-200 divide-y divide-gray-100 overflow-x-hidden overflow-y-auto max-h-[450px]">
          {items.map(item => <CartItem className="py-5" key={item.variant.id} item={item} />)}
        </ul>
        <dl className="py-4 gap-y-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <dt className="text-sm text-gray-600">Subtotal</dt>
            <dd className="text-sm font-medium text-gray-900">{currencyFormatter.format(subTotal)}</dd>
          </div>
        </dl>
      </>)}

      {items.length === 0 && <p className="mt-4 border-t pt-4 pb-2 text-gray-600 text-center">There are no items in the cart.</p>}
      
      {items.length > 0 && (
        <button 
          onClick={handleClickedCheckout}
          type="button" 
          className="block mt-5 w-full items-center justify-center uppercase font-medium text-center px-6 py-4 rounded text-black bg-primary hover:opacity-80 active:opacity-70 checkout-button"
        >
          Checkout
        </button>
      )}
    </div>
  </>
}