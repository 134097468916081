import Image from 'next/image'
import Link from 'next/link'

import useCart from '../store/cart'

const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
})

export default function CartItem({ className, item }) {
  const removeFromCart = useCart((state) => state.removeItem)
  const variantImage = item.product.images.find(image => Object.values(image.variant_ids).includes(item.variant.id))
  const featuredImage = variantImage || item.product.images[0]
  const availableToSell = item.variant.stock > 0

  let allKitTitles = ['The Complete kit','The Bedroom kit','The Kitchen kit', 'The Bathroom kit', 'The International kit', 'The Ultimate kit']

  return (
    <li className={`${className} flex items-center`}>
      <Link href={`/products/${item.product.id}`}>
        <div className="cursor-pointer flex-shrink-0 relative w-24 h-24 rounded-md object-center object-cover">
          <Image
            src={featuredImage.src}
            alt={item.variant.title}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        </div>
      </Link>

      <div className="ml-6 flex-1 flex flex-col" style={{marginLeft: '12px'}}>
        <div className="flex">
          <div className="min-w-0 flex-1">
            <h4 className="text-sm">
              <Link href={`/products/${item.product.id}`}>
                <a className={`font-medium text-gray-700 hover:text-gray-800 ${!availableToSell ? 'line-through' : ''}`}>{item.product.title}</a>
              </Link>
            </h4>
            <div className="mt-2 flex text-sm">
              {item.variant.option1 && <p className="text-gray-500">{item.variant.option1}</p>}
              {item.variant.option2 && <p className="ml-2 pl-2 border-l border-gray-200 text-gray-500">{item.variant.option2}</p>}
              {item.variant.option3 && <p className="ml-2 pl-2 border-l border-gray-200 text-gray-500">{item.variant.option3}</p>}
            </div>
          </div>

          <div className="ml-4 flex-shrink-0 flow-root">
            <button onClick={() => removeFromCart(item)} type="button" className="-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500">
              <span className="sr-only">Remove</span>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#383E49" aria-hidden="true">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex-1 pt-2 flex items-end justify-between">
          <div>
            {allKitTitles.includes(item.product.title) ? <p style={{textDecoration: 'line-through'}} className={`mt-1 text-sm font-medium ${availableToSell ? 'text-gray-900' : 'text-red-500'}`}>{availableToSell ? currencyFormatter.format((item.variant.price * 1.1)): 'Out of stock'}</p>: <p></p>}
            <p className={`mt-1 text-sm font-medium ${availableToSell ? 'text-gray-900' : 'text-red-500'}`}>{availableToSell ? currencyFormatter.format(item.variant.price): 'Out of stock'}</p>
          </div>
          {availableToSell && <p className="ml-4 text-sm font-medium text-gray-600">x {item.quantity}</p>}
        </div>
      </div>
    </li>
  )
}