import { useRouter } from "next/router"
import Image from 'next/image'

import BackLink from "./BackLink"
import Cart from "./Cart"

export default function Layout({backLink, children}) {
  const router = useRouter()
  const currentPage = router.pathname

  return (
    <div className="mx-auto px-4 md:px-8">
      <div className="sale-banner-container">
        <div className="sale-banner-inner">
          <h2>Unite Students save 10% on kits!</h2>
        </div>
      </div>
      <header className="flex items-center justify-between relative py-4 md:py-5">
        {!backLink && (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">Select a tab</label>
              <select value={currentPage} onChange={e => router.push(e.target.value)} id="tabs" name="tabs" className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" style={{borderRadius: '15px', width: '100px', padding: '10px'}}>
                <option value="/">Kits</option>
                <option value="/extras">Extras</option>
              </select>
            </div>

            <div className="hidden sm:block">
              <nav className="flex menu-buttons-container" aria-label="Tabs">
                <button onClick={() => router.push('/')} type="button" className={`${currentPage === '/' ? 'bg-gray-100 text-gray-700 menu-button--active' : 'text-gray-500 hover:text-gray-700'} px-4 py-3 font-medium rounded-md menu-button`}>Kits</button>
                <button onClick={() => router.push('/extras')} type="button" className={`${currentPage === '/extras' ? 'bg-gray-100 text-gray-700 menu-button--active' : 'text-gray-500 hover:text-gray-700'} px-4 py-3 font-medium rounded-md menu-button`}>Extras</button>
              </nav>
            </div>
          </div>
        )}
        {backLink && <BackLink href={backLink} />}
        <span />
        <Cart />
      </header>
      <main className="relative py-4 md:py-5">
        {children}
      </main>
      <footer className="mx-auto px-4 md:px-8 py-4 border-t border-gray-100 mt-10">
        <div className="md:flex md:items-center md:justify-between font-light">
          <div className="text-center md:text-left text-sm text-gray-400 flex items-center">
              Powered by 
              <a className="mx-1.5 inline-flex items-center no-underline" href="https://www.unikitout.com?partner=Unite%20Students" target="_blank" rel="noreferrer">
                <Image src="https://cdn.shopify.com/s/files/1/1233/0208/t/14/assets/uko@2x.png?v=85612125613215983951652713122" width={'25px'} height={'25px'} alt="UniKitOut Logo" className="w-6 h-auto flex-shrink-0" />
                <span className="ml-1 text-[#9dd5d0]">UniKitOut</span>
              </a> 
              &copy; 2016
          </div>

          <div className="mt-4 flex items-center md:justify-center md:mt-0">
            <div className="flex flex-wrap gap-x-2 md:gap-x-8 gap-y-2">
              <a href="https://www.unikitout.com/pages/privacy?partner=Unite%20Students" target="_blank" className="no-underline text-sm text-gray-400 hover:text-gray-600" rel="noreferrer">Privacy</a>
              <a href="https://www.unikitout.com/pages/terms-conditions?partner=Unite%20Students" target="_blank" className="no-underline text-sm text-gray-400 hover:text-gray-600" rel="noreferrer">Terms & Conditions</a>
              <a href="https://www.unikitout.com/pages/testimonials?partner=Unite%20Students" target="_blank" className="no-underline text-sm text-gray-400 hover:text-gray-600" rel="noreferrer">Testimonials</a>
              <a href="https://www.unikitout.com/pages/faqs?partner=Unite%20Students" target="_blank" className="no-underline text-sm text-gray-400 hover:text-gray-600" rel="noreferrer">FAQ</a>
              <a href="https://www.unikitout.com/pages/contact-us?partner=Unite%20Students" target="_blank" className="no-underline text-sm text-gray-400 hover:text-gray-600" rel="noreferrer">Contact Us</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}