import Link from "next/link"

export default function BackLink({ href }) {
  if (!href) return null

  return (
    <Link href={href}>
      <a className="group flex items-center no-underline back-button">
        <svg className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
      </a>
    </Link>
  )
}