import create from 'zustand'
import { persist } from 'zustand/middleware'

const useCart = create(
  persist(
    (set, get) => ({
      isOpen: false,
      items: [],
      setIsOpen: (isOpen) => set({ isOpen }),
      addItem: (item) => {
        set(state => {
          const items = state.items
          const foundItem = items.find(i => i.variant.id === item.variant.id)

          if (foundItem) {
            foundItem.quantity += 1
          } else {
            items.push(item)
          }

          return { items }
        })
      },
      removeItem: (item) => {
        set(state => {
          return { 
            items: state.items.filter((_item, index) => {
              return index !== state.items.indexOf(item)
            })
          }
        })
      },
      updateItemStock: (item, product) => {
        set(state => {
          return { 
            items: state.items.map(i => {
              if (i.variant.id !== item.variant.id) return i
              const variant = product.variants.find(v => v.id === item.variant.id)
              if (!variant) return i
              return {
                variant,
                product,
                quantity: i.quantity,
              }
            })
          }
        })
      },
      clearCart: () => set(state => ({ items: [] }))
    }),
    { 
      name: 'cart',
      partialize: (state) => ({ items: state.items })
    }
  )
)

export default useCart